import dayjs from 'dayjs';

import { DAY_TO_HOUR, HOUR_TO_MIN, WEEK_TO_DAY } from '@zaritalk/constants';
import { DateFormatType } from '@zaritalk/types';

export const parseDateToFormat = (date: Date): string => {
  const yyyy = date.getFullYear();
  const mm = date.getMonth() + 1;
  const dd = date.getDate();

  return `${yyyy}-${mm > 9 ? mm : '0' + mm}-${dd > 9 ? dd : '0' + dd}`;
};

export const parseDateToDateString = (date: Date): string => {
  return dayjs(date).format('YYYY-MM-DD HH:mm:ss');
};

export const parseDateToFullKorean = (date: Date): string => {
  const yyyy = date.getFullYear();
  const mm = date.getMonth() + 1;
  const dd = date.getDate();

  return `${yyyy}년 ${mm > 9 ? mm : '0' + mm}월 ${dd > 9 ? dd : '0' + dd}일`;
};

export const parseDateToString = (date: Date, separator = ''): string => {
  return `${date.getFullYear()}${separator}${parseTwoDigit(date.getMonth() + 1)}`;
};

export const parseFullDateToString = (date: Date, separator = ''): string => {
  return `${date.getFullYear()}${separator}${parseTwoDigit(date.getMonth() + 1)}${separator}${parseTwoDigit(
    date.getDate(),
  )}`;
};

export const parseTwoDigit = (num: number): string => {
  return ('0' + num).slice(-2);
};

export const changeSeparator = (targetStr: string, targetSeparator: string, changeSeparator: string): string => {
  return targetStr.split(targetSeparator).join(changeSeparator);
};

export const createCounterText = (date: Date): string => {
  const tomorrow = new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 0, 0, 0);
  let seconds = tomorrow.getTime() - date.getTime();

  seconds /= 1000;
  seconds %= 86400;

  const hh = parseInt(String(seconds / 3600), 10);
  seconds %= 3600;

  const mm = parseInt(String(seconds / 60), 10);
  const ss = parseInt(String(seconds % 60), 10);

  return `${hh}시간 ${mm}분 ${ss}초`;
};

export const DAY_KO = ['일', '월', '화', '수', '목', '금', '토'];

export const getDay = (date: Date): string => {
  const day = date.getDay();

  return DAY_KO[day];
};

export const getMonthLastDate = (date: Date): number => {
  return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
};

export const getMonthLastDay = (date: Date): string => {
  return getDay(new Date(date.getFullYear(), date.getMonth() + 1, 0));
};

export const getExpiresDate = (unit: number, dateFormatType: DateFormatType = 'day'): string => {
  const date = new Date();

  let expire = 1000;
  if (dateFormatType === 'sec') {
    expire *= unit;
  } else if (dateFormatType === 'min') {
    expire *= 60 * unit;
  } else if (dateFormatType === 'hour') {
    expire *= 60 * 60 * unit;
  } else {
    expire *= 60 * 60 * 24 * unit;
  }

  date.setTime(date.getTime() + expire);

  return date.toUTCString();
};

export const convertToDate = (stringDate: string): Date => {
  return dayjs(stringDate).toDate();
};

export const getRegisterDate = (date: Date, formatTemplate = 'YY-MM-DD'): string => {
  const now = dayjs();
  const targetDate = dayjs(date);

  const diffMinute = now.diff(targetDate, 'minute');
  if (diffMinute < HOUR_TO_MIN) {
    return diffMinute <= 0 ? '방금' : `${diffMinute}분 전`;
  }

  const diffHour = now.diff(targetDate, 'hour');
  if (diffHour <= DAY_TO_HOUR) {
    return `${diffHour}시간 전`;
  }

  const diffDate = now.diff(targetDate, 'd');
  return diffDate <= WEEK_TO_DAY ? `${diffDate}일 전` : `${targetDate.format(formatTemplate)}`;
};

export const getRegisterDateFromString = (stringDate: string): string => {
  return getRegisterDate(convertToDate(stringDate));
};
