export const VACANCY_MAP_ID = 'vacancy-map';
export const VACANCY_DETAIL_MAP_ID = 'vacancy-detail-map';
export const INIT_LATITUDE = 37.5175066;
export const INIT_LONGITUDE = 127.0473753;
export const INIT_POSITION = { latitude: INIT_LATITUDE, longitude: INIT_LONGITUDE };

// POI(Points Of Interest): 지도에서 관심을 가지는 지점 및 위계
export const POI = {
  SI_DO: 'SI_DO',
  SI_GUN_GU: 'SI_GUN_GU',
  EUP_MYEON_DONG: 'EUP_MYEON_DONG',
  VACANCIES: 'VACANCIES',
} as const;

export const MARKER_TYPE_MATCHED_ZOOM_LEVEL = {
  SI_DO: 0,
  SI_GUN_GU: 12,
  VACANCIES: 14,
} as const;

export const INIT_ZOOM = MARKER_TYPE_MATCHED_ZOOM_LEVEL.VACANCIES;
export const MAX_ZOOM = 16;

export const HEADER_HEIGHT = 56;
export const FILTER_HEIGHT = 61;
