import { CookieAttributes } from 'js-cookie';

import { useCookieState } from '@zaritalk/global-state';

export default function useCookie(
  key: string,
  initialValue = '',
): [
  cookieItem: string,
  setValue: (value: any, options?: CookieAttributes) => void,
  removeValue: (path?: string, domain?: string) => void,
] {
  const { cookieItem, setValue, removeValue } = useCookieState(key, initialValue);

  return [cookieItem, setValue, removeValue];
}
