import axios, { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { GetServerSidePropsContext } from 'next';

import { API_URL, ZARITALK_DEV_API } from '@zaritalk/constants';
import { noop } from '@zaritalk/utils/lib/operation';

import { axiosErrorHandler } from './errorHanleMiddleware';
import { ApiReturn, slackReportFetcher, checkAuthToken } from '../../index';

export const postWithToken = async <T, R>(
  url: string,
  data: T,
  reqConfig?: { [key: string]: string },
  ctx?: GetServerSidePropsContext,
): Promise<ApiReturn<R>> => {
  const generalConfig = checkAuthToken({ reqConfig, req: ctx?.req ?? undefined }) as AxiosRequestConfig;

  try {
    const res = await axios.post<T, AxiosResponse<ApiReturn<R>>>(`${API_URL}${url}`, data, { ...generalConfig });
    return res.data;
  } catch (err) {
    const catchError = err as Error | AxiosError<T>;
    return axiosErrorHandler<ApiReturn<R>>((res) => {
      if (axios.isAxiosError(res)) {
        const response = res.response as AxiosResponse<ApiReturn<R>>;
        slackReportFetcher('POST', url, data, response, ctx).catch(noop);

        throw res;
      } else {
        throw res;
      }
    })(catchError);
  }
};

export const postWithoutToken = async <T, R>(
  url: string,
  data: T,
  reqConfig?: { [key: string]: any },
): Promise<ApiReturn<R>> => {
  try {
    const res = await axios.post<T, AxiosResponse<ApiReturn<R>>>(`${API_URL}${url}`, data, { ...reqConfig });
    return res.data;
  } catch (err) {
    const catchError = err as Error | AxiosError<T>;
    return axiosErrorHandler<ApiReturn<R>>((res) => {
      if (axios.isAxiosError(res)) {
        const response = res.response as AxiosResponse<ApiReturn<R>>;
        slackReportFetcher('POST', url, data, response).catch(noop);

        throw res;
      } else {
        throw res;
      }
    })(catchError);
  }
};

export const postInternalWithoutTokenApi = async <T, R>(
  url: string,
  data: T,
  reqConfig?: { [key: string]: any },
): Promise<ApiReturn<R>> => {
  try {
    const res = await axios.post<T, AxiosResponse<ApiReturn<R>>>(url, data, reqConfig);
    return res.data;
  } catch (err) {
    const catchError = err as Error | AxiosError<T>;
    return axiosErrorHandler<ApiReturn<R>>((res) => {
      if (axios.isAxiosError(res)) {
        const response = res.response as AxiosResponse<ApiReturn<R>>;
        slackReportFetcher('POST', url, data, response, undefined, 'internal').catch(noop);

        throw res;
      } else {
        throw res;
      }
    })(catchError);
  }
};

export const postInternalApi = async <T, R>(
  url: string,
  data: T,
  reqConfig?: { [key: string]: string },
): Promise<ApiReturn<R>> => {
  const generalConfig = checkAuthToken({ reqConfig }) as AxiosRequestConfig;

  try {
    const res = await axios.post<T, AxiosResponse<ApiReturn<R>>>(url, data, { ...generalConfig });
    return res.data;
  } catch (err) {
    const catchError = err as Error | AxiosError<T>;
    return axiosErrorHandler<ApiReturn<R>>((res) => {
      if (axios.isAxiosError(res)) {
        const response = res.response as AxiosResponse<ApiReturn<R>>;
        slackReportFetcher('POST', url, data, response, undefined, 'internal').catch(noop);

        throw res;
      } else {
        throw res;
      }
    })(catchError);
  }
};

export const postDevWithToken = async <T, R>(
  url: string,
  data: T,
  reqConfig?: { [key: string]: string },
  ctx?: GetServerSidePropsContext,
): Promise<ApiReturn<R>> => {
  const generalConfig = checkAuthToken({ reqConfig, req: ctx?.req ?? undefined }) as AxiosRequestConfig;

  try {
    const res = await axios.post<T, AxiosResponse<ApiReturn<R>>>(`${ZARITALK_DEV_API}${url}`, data, {
      ...generalConfig,
    });
    return res.data;
  } catch (err) {
    const catchError = err as Error | AxiosError<T>;
    return axiosErrorHandler<ApiReturn<R>>((res) => {
      if (axios.isAxiosError(res)) {
        const response = res.response as AxiosResponse<ApiReturn<R>>;
        slackReportFetcher('POST', url, data, response, ctx).catch(noop);

        throw res;
      } else {
        throw res;
      }
    })(catchError);
  }
};

export const postMockWithToken = async <T, R>(
  url: string,
  data: T,
  reqConfig?: { [key: string]: any },
): Promise<ApiReturn<R>> => {
  try {
    const res = await axios.post<T, AxiosResponse<ApiReturn<R>>>(url, data, { ...reqConfig });
    return res.data;
  } catch (err) {
    const catchError = err as Error | AxiosError<T>;
    return axiosErrorHandler<ApiReturn<R>>((res) => {
      if (axios.isAxiosError(res)) {
        const response = res.response as AxiosResponse<ApiReturn<R>>;
        slackReportFetcher('POST', url, data, response).catch(noop);

        throw res;
      } else {
        throw res;
      }
    })(catchError);
  }
};

export const postExternalAPIWithToken = async <T, R>(
  url: string,
  data: T,
  token: string,
  config = {},
  authScheme: 'Bearer' | 'Basic' = 'Bearer',
): Promise<R> => {
  try {
    const res = await axios.post<T, AxiosResponse<R>>(url, data, {
      headers: { Authorization: `${authScheme} ${token}`, ...config },
    });
    return res.data;
  } catch (err) {
    const catchError = err as Error | AxiosError<T>;
    return axiosErrorHandler<R>((res) => {
      if (axios.isAxiosError(res)) {
        const response = res.response as AxiosResponse<R>;
        slackReportFetcher('POST', url, data, response, undefined, 'external').catch(noop);

        throw res;
      } else {
        throw res;
      }
    })(catchError);
  }
};
