export const ALERT_MSG = {
  SUCCESS_CERTI_NUM: '인증번호를 보냈습니다.</br> 문자를 확인해주세요.',
  SUCCESS_AUTH: '인증되었습니다.',
  SUCCESS_TALK_DENIED: '알림톡이 수신거부 됐습니다.',
};

type TErrorMessage = typeof ERROR_MSG_STATIC & { [key: string]: string };

const ERROR_MSG_STATIC = {
  DEFAULT: '일시적인 오류입니다.\n잠시후 다시 시도해주세요.',
  'token expired': '토큰이 만료되었습니다.',
  'invalid cert number': '인증번호를 확인해주세요',
  'not found lessor': '알 수 없는 회원입니다.',
  'not found contract': '알 수 없는 계약입니다.',
  'not found lessorBuilding': '알 수 없는 건물입니다.',
  'not found lessorBuildingRoom': '호실 정보가 잘못되었습니다.',
  'not found paymentHis': '알 수 없는 수납내역입니다.',
  'exist same room name': '이미 있는 호실입니다.\n호실을 확인해주세요.',
  'unauthorized request': '권한이 없습니다.',
  'Finance account holder name is not found': '예금주를 찾지 못했습니다.',
  'has already lessee payment': '자리페이 결제 내역이 있는 경우\n결제 취소 후 탈퇴해 주세요.',
  'duplicated contract period': '계약기간이 겹칩니다.\n계약기간을 확인해주세요.',
  'illegal payment history state for update contract':
    '임대인이 입력한 계약기간과 다릅니다.\n계약기간 수정이 필요하실 경우 자리톡 고객센터로 알려주세요.',
  '계약기간보다 미납개월 수가 많습니다.<br> 계약기간 또는 미납개월 수를 확인해주세요.':
    '미납개월 수가 과거 계약기간보다 많습니다.',
  NOT_FOUND_LEASE_TYPE: '임대 유형을 선택해주세요.',
  NOT_FOUND_ROOM_NAME: '호실을 입력해주세요.',
  NOT_FOUND_LESSEE_NAME: '세입자 이름을 입력해주세요.',
  NOT_FOUND_LESSEE_PHONENUM: '세입자 휴대폰 번호를 입력해주세요.',
  NOT_FOUND_LESSOR_PHONENUM: '임대인(집주인) 휴대폰 번호를 입력해주세요.',
  INVALID_LESSEE_PHONENUM: '휴대폰 번호가 올바르지 않습니다.',
  NOT_FOUND_DEPOSIT: '보증금을 입력해주세요.',
  NOT_FOUND_SELLING: '매매가를 입력해주세요.',
  INVALID_FOUND_DEPOSIT: '보증금은 0원보다 작을 수 없습니다.',
  OVER_DEPOSIT: '보증금이 너무 큽니다.\n금액 입력 단위는 만원입니다.',
  NOT_FOUND_MONTHLY_RENT: '월 임대료를 입력해주세요.',
  INVALID_FOUND_MONTHLY_RENT: '월 임대료는 0원보다 작을 수 없습니다.',
  OVER_MONTHLY_RENT: '임대료가 너무 큽니다.\n금액 입력 단위는 만원입니다.',
  NOT_FOUND_MAIN_FEE: `월 관리비를 입력해주세요. </br><p style='font-size: 14px; font-weight: normal; padding-top: 8px;'>관리비를 받지 않거나<br/>매달 변동될 경우 '관리비 없음'을 눌러주세요.</p> `,
  INVALID_FOUND_MAIN_FEE: '월 관리비는 0원보다 작을 수 없습니다.',
  OVER_MAIN_FEE: '관리비가 너무 큽니다.\n금액 입력 단위는 만원입니다.',
  NOT_FOUND_PAYMENT_DATE: '임대료(관리비) 수납일을 선택해주세요.',
  NOT_FOUND_OVERDUE_MONTH: '미납개월수를 입력해주세요. </br>미납내역이 없다면 0을 입력해주세요.',
  NOT_FOUND_CONTRACT_START: '계약시작일을 선택해주세요.',
  NOT_FOUND_CONTRACT_END: '계약종료일을 선택해주세요.',
  INVALID_TRADABLE_STATUS: '거래 가능 여부를 선택해주세요.',
  INVALID_CONTRACT_DATE: '계약 종료일이 </br>계약 시작일보다 이전입니다.',

  NOT_FOUND_ADDRESS: '주소를 선택해주세요.',
  NOT_COLLECT_ADDRESS: '다른 주소를 선택해주세요.',

  NOT_FOUND_BUILDING_ROOM: '임대장부 호실을 선택해주세요.',
  NOT_FOUND_DONG: '동을 입력해주세요.',
  NOT_FOUND_HO: '호실을 입력해주세요.',
  NOT_FOUND_ROOM_COUNT: '방 개수를 선택해주세요.',
  NOT_FOUND_TOILET_COUNT: '화장실 개수를 선택해주세요.',
  NOT_FOUND_TOTAL_STORY: '전체 층을 선택해주세요',
  NOT_FOUND_FLOOR: '해당 층을 선택해주세요',
  NOT_FOUND_NET_LEASABLE_AREA: '전용면적을 입력해주세요.',
  NOT_FOUND_NET_SUPPLY_AREA: '공급면적을 입력해주세요.',
  NOT_FOUND_PARKING_AVAILABLE: '주차 가능 여부를 선택해주세요.',
  NOT_FOUND_PARKING_FEE: '주차 요금을 입력해주세요.',
  NOT_FOUND_SHOR_TERM_AVAILABLE: '단기 임대 여부를 선택해주세요.',
  NOT_FOUND_DESCRIPTION: '매물 소개를 입력해주세요.',
  NOT_FOUND_CONTRACT_END_DATE: '퇴거 예정일을 선택해주세요.',

  INVALID_REAL_ESTATE: '매물 종류를 선택해주세요.',
  INVALID_REAL_ESTATE_PRICE_TYPE: '매물 가격을 선택해주세요.',
  INVALID_HOUSE_OWNER_TYPE: '집주인 유형을 선택해주세요.',

  FAIL_SCHEDULE_CREATE: '반복일정을 만드는데 실패하였습니다.',
  INVALID_SCHEDULE_FREQUENCY_TYPE: '반복일정 유형을 선택해주세요.',
  INVALID_SCHEDULE_DAY_OF_MONTH: '날짜를 선택해주세요.',
  INVALID_SCHEDULE_DAY_OF_WEEK: '요일을 선택해주세요',
};

export const ERROR_MSG: TErrorMessage = ERROR_MSG_STATIC;

type TDataLayer = typeof DATALAYER_STATIC & { [key: string]: string };

export const DATALAYER_STATIC = {
  INTRO_VISIT: 'intro_visit',

  HOST_PHONENUM_CERTIFY_COMPLETE: 'host_phonenum_certify_complete',
  INTRO_KAKAOSYNC_COMPLETE: 'intro_kakaosync_complete',

  ADD_BUILDING_COMPLETE: 'add_building_complete',

  FIRST_ADD_ROOM_COMPLETE: 'first_add_room_complete', // 첫 호실 추가
  FIRST_ADD_GUEST_COMPLETE: 'first_add_guest_complete', // 첫 세입자 추가

  VISIT_COMMUNITY_POPUP: 'visit_community_popup', // 공유하기 등을 통해 로그인 안내 팝업창이 떴을 때

  COMMUNITY_POPUP_BT_KAKAO: 'community_popup_bt_kakao', // community_popup 에서 카카오 버튼을 눌렀을 때
  COMMUNITY_POPUP_BT_PHONE: 'community_popup_bt_phone', // community_popup 에서 휴대폰 인증 버튼을 눌렀을 때

  COMMUNITY_POPUP_SIGNUP_KAKAO: 'community_popup_signup_kakao', // community_popup 에서 카카오 인증을 통해 회원가입을 했을 때
  COMMUNITY_POPUP_SIGNUP_PHONE: 'community_popup_signup_phone', // community_popup 에서 휴대폰 인증을 통해 회원가입을 했을 때

  VISIT_INTRO_APP: 'visit_intro_app', // intro/app 페이지 접속했을 때

  VISIT_APP_BT_KAKAO: 'intro_app_bt_kakao', // intro/app 페이지에서 하단 카카오 버튼을 눌렀을 때
  VISIT_APP_BT_PHONE: 'intro_app_bt_phone', // intro/app 페이지에서 하단 휴대폰 인증 버튼을 눌렀을 때

  VISIT_APP_SIGNUP_KAKAO: 'intro_app_signup_kakao', // intro/app 페이지에서  카카오 인증 통해 회원가입을 했을 때
  VISIT_APP_SIGNUP_PHONE: 'intro_app_signup_phone', // intro/app 페이지에서  휴대폰 인증을 통해 회원가입을 했을 때

  // LESSEE
  CLICK_CONFIRM_LESSEE: 'click_confirm_lessee', // 임대인 확인 요청하기 버튼 클릭
  CLICK_SHARE_BILL: 'click_share_bill', // 고지서 공유하기 버튼 클릭
  VISIT_BILL: 'visit_bill', // 고지서 페이지 방문
  SIGN_UP_LESSEE: 'sign_up_lessee', // 세입자 가입
  COMPLETE_ADD_LESSOR: 'complete_add_lessor', // 고지서 작성 완료
  SIGN_UP_BY_LESSEE: 'sign_up_by_lessee', // 세입자 고지서에 의한 임대인 가입
  CLICK_POPUP_APP_INSTALL_LESSEE: 'click_popup_app_install_lessee', // 팝업 앱 설치하기 버튼 클릭
  CLICK_HOME_APP_INSTALL_LESSEE: 'click_home_app_install_lessee', // 홈 앱 설치하기 버튼 클릭
  CREATE_BUILDING_BILL_FIRST: 'create_building_bill_first', // 고지서 건물 주소 선택
  CREATE_ROOM_BILL_FIRST: 'create_room_bill_first', // 고지서 LeaseType 선택
  CREATE_FEE_BILL_FIRST: 'create_fee_bill_first', // 고지서 비용 입력
  CREATE_TERM_BILL_FIRST: 'create_term_bill_first', // 계약시작일 종료일 선택
  CREATE_ROOM_CONTACT: 'create_room_contact', // 주소록 가입 LeaseType 선택한 경우
  CREATE_FEE_CONTACT: 'create_fee_contact', // 주소록 가입 납부일 선택한 경우
  CREATE_TERM_CONTACT: 'create_term_contact', // 주소록 가입 계약시작일과 종료일을 모두 남긴 경우
  CREATE_CONTRACT_CONTACT: 'create_contract_contact', // 주소록 가입 완료버튼 누른 경우
  VISIT_CONTACT: 'visit_contact', // 주소록 온보딩 접속한 경우LesseeBillModifyRequestButton
  REQUEST_PAYMENT_HISTORY_BILL: 'request_payment_history_bill', // 납부내역 확인요청 팝업 동의 버튼 누른 경우
  REQUEST_PAYMENT_BILL: 'request_payment_bill', // 임금완료 확인요청 팝업 동의 버튼 누른 경우

  // REALTOR
  SIGN_UP_REALTOR: 'sign_up_realtor', // 중개인 가입
  COMPLETE_REALTOR_INFO: 'complete_realtor_info', // 중개인 온보딩 완료
  VISIT_REALTOR: 'visit_gongsil', // 공실 리스트 페이지 방문
  VISIT_VACANCY: 'visit_vacancy', // 공실 상세 페이지 방문
  OCCUPY_VACANCY: 'occupy_vacancy', // 매물 선점 완료

  // PAY
  PAYMENT_COMPLETE_ZARIPAY: 'PAYMENT_COMPLETE_ZARIPAY', // 자리페이 - 결제완료
  REGISTER_LEASE_AGREEMENT_FILE: 'REGISTER_LEASE_AGREEMENT_FILE', // 자리페이 - 계약서 등록 완료
} as const;

export const DATALAYER_EVENT: TDataLayer = DATALAYER_STATIC;
