import { useRouter } from 'next/router';
import { ReactNode, useEffect, useState } from 'react';
import { Transition, TransitionGroup } from 'react-transition-group';

import { useNavigationState } from '@zaritalk/global-state';
import { css } from '@zaritalk/panda-css/css';
import { styled } from '@zaritalk/panda-css/jsx';

const TRANSITION_TIME = 100;

interface PageTransitionLayoutProps {
  children: ReactNode;
  navigationRouteOrder: string[];
}

type TTransitionType = 'entering' | 'entered' | 'exitingLeft' | 'exitingRight';

function PageTransitionLayout({ children, navigationRouteOrder }: PageTransitionLayoutProps) {
  const { setIsShowBottomNavigation } = useNavigationState();
  const [isAnimation, setIsAnimation] = useState<boolean>(false);
  const [direction, setDirection] = useState<'left' | 'right'>('left');

  const router = useRouter();

  useEffect(() => {
    const handleRouterChange = (path: string) => {
      const currentPath = router.asPath.split('?')[0];
      const nextPath = path.split('?')[0];

      if (navigationRouteOrder.includes(currentPath) && navigationRouteOrder.includes(nextPath)) {
        const currentPathIndex = navigationRouteOrder.findIndex((path) => path === currentPath);
        const nextPathIndex = navigationRouteOrder.findIndex((path) => path === nextPath);

        if (currentPathIndex < nextPathIndex) {
          setDirection('left');
        } else {
          setDirection('right');
        }
      } else {
        setIsAnimation(false);
      }

      if (navigationRouteOrder.includes(nextPath)) {
        setIsShowBottomNavigation(true);
      } else {
        setIsShowBottomNavigation(false);
      }
    };

    const currentPath = router.asPath.split('?')[0];
    if (navigationRouteOrder.some((route) => currentPath === route)) {
      setIsAnimation(true);
      setIsShowBottomNavigation(true);
    } else {
      setIsAnimation(false);
      setIsShowBottomNavigation(false);
    }

    router.events.on('routeChangeStart', handleRouterChange);

    return () => {
      router.events.off('routeChangeStart', handleRouterChange);
    };
  }, [router, setIsShowBottomNavigation]);

  return (
    <TransitionGroup className={css({ position: 'relative' })}>
      <Transition
        key={router.pathname}
        timeout={{
          enter: isAnimation ? TRANSITION_TIME : 0,
          exit: isAnimation ? TRANSITION_TIME : 0,
        }}
      >
        {(status) => {
          const type =
            status === 'exiting' ? `${status}${direction.charAt(0).toUpperCase()}${direction.slice(1)}` : status;
          return <TransitionDiv type={type as TTransitionType}>{children}</TransitionDiv>;
        }}
      </Transition>
    </TransitionGroup>
  );
}

export default PageTransitionLayout;

const TransitionDiv = styled('div', {
  variants: {
    type: {
      entering: {
        position: 'absolute',
        transform: 'translateX(0)',
        opacity: 0,
      },
      entered: {
        transition: `transform ${TRANSITION_TIME}ms ease-in-out`,
        opacity: 1,
      },
      exitingLeft: {
        transition: `transform ${TRANSITION_TIME}ms ease-in-out`,
        transform: 'translateX(-5%)',
        opacity: 1,
      },
      exitingRight: {
        transition: `transform ${TRANSITION_TIME}ms ease-in-out`,
        transform: 'translateX(5%)',
        opacity: 1,
      },
    },
  },
});
