import { useMutation, useQueryClient } from '@tanstack/react-query';

import { ERROR_MSG } from '@zaritalk/constants';
import { useAlertState } from '@zaritalk/global-state';
import { ViewMode, TZaritalkMemberType } from '@zaritalk/types';

import { generateLessorDetailKey } from '../../../';
import { ApiError, putWithToken } from '../../../utils';

export interface IModifyLessorDetail {
  referer?: string;
  manageRoomCount?: number;
  hostViewMode?: ViewMode;
  fcmToken?: string;
  deviceInfo?: string;
  selectedMemberType?: TZaritalkMemberType;
  inputRoomCount?: number;
  signUpSource?: string;
}

export const fetchModifyLessorDetail = (data: IModifyLessorDetail) => {
  return putWithToken('/lessor/detail', data);
};

export const useModifyLessorDetail = () => {
  const { setAlertText } = useAlertState();
  const queryClient = useQueryClient();

  return useMutation(fetchModifyLessorDetail, {
    async onSuccess() {
      await queryClient.invalidateQueries([generateLessorDetailKey()]);
    },
    onError(error: ApiError) {
      const message = error?.response?.data?.message ?? '';
      if (error.code !== 'ECONNABORTED') {
        setAlertText(ERROR_MSG[message] ?? '일시적인 오류입니다.<br/>잠시후 다시 시도해주세요.');
      }
    },
  });
};
