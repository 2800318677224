export const STATUS_CODE = {
  BAD_REQUEST: 400,
  UNAUTHORIZED: 401,
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  NOT_ACCEPTABLE: 406,
  DUPLICATED: 409,
  INTERNAL_SERVER_ERROR: 500,
  BAD_GATEWAY: 502,
};
